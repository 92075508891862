import GraphQlMixin from '@/mixins/graphql.mixin'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import { mapActions } from 'vuex'

export default {
  mixins: [GraphQlMixin],
  components: {
    FullScreenLoader
  },
  data() {
    return {
      ready: false
    }
  },
  watch: {
    ability: {
      handler() {
        this.checkUserPermission()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    checkUserPermission() {
      if (this.ability.can('read', 'Asset')) {
        this.$router.push('/assets/allAssets')
      } else if (this.ability.can('read', 'IntraAccount')) {
        this.$router.push('/users')
      } else if (this.ability.can('read', 'Report')) {
        this.$router.push('/reports')
      } else if (this.ability.can('read', 'App')) {
        this.$router.push('/search')
      } else {
        this.ready = true
      }
    }
  },
  created() {
    this.checkUserPermission()
  }
}
